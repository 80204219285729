<template>
    <div class="row">
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('FullName') }}</div>
                <div class="value">{{ User.name }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('PhoneNumber') }}</div>
                <div class="value">{{ User.phone }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Email') }}</div>
                <div class="value">{{ User.email }}</div>
            </div>
        </div>
        <div class="col-md-6" v-if="User.city != null">
            <div class="item">
                <div class="title">{{ $t('Adress') }}</div>
                <div class="value">{{ User.city?.name[this.$i18n.locale] }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Tax_Number') }}</div>
                <div class="value">{{ Company.tax_number }}</div>
            </div>
        </div>

    </div>
</template>
<script>
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
    data() {
        return {
            User:cookie.get('Userdata'),
            Company:cookie.get('Companydata'),
        };
    },
}
</script>